<template>
  <div class="dropdown">
    <div
      :class="publish + '-dropdown'"
      id="selectDiv"
      @click="closeDropdown"
      :value="publish"
    >
      <div>
        {{ publish }}
      </div>
      <div v-if="publish === 'Publish'">
        <img src="../../../../assets/images/elements/PublishDropdown.svg" />
      </div>
      <div v-if="publish === 'Unpublish'">
        <img src="../../../../assets/images/elements/UnpublishDropdown.svg" />
      </div>
      <div v-if="publish === 'Schedule'">
        <img src="../../../../assets/images/elements/ScheduleDropdown.svg" />
      </div>
    </div>
  </div>
</template>
  
<script>
import ClickOutside from "vue-click-outside";

export default {
  mounted() {
    this.isPublish();
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      options: ["Publish", "Unpublish", "Schedule"],
      status: {
        name: "",
        options: [],
      },
      selectedOption: "",
      allowedOptions: [],
      publish: "",
    };
  },
  computed: {},
  methods: {
    isPublish() {
      if (this.is_publish) {
        this.publish =
          this.is_publish.is_publish === "unpublish"
            ? "Unpublish"
            : this.is_publish.is_publish === "publish"
            ? "Publish"
            : "Schedule";
      } else {
        this.publish =
          this.params.value.is_publish === "unpublish"
            ? "Unpublish"
            : this.params.value.is_publish === "publish"
            ? "Publish"
            : "Schedule";
        console.log("publish: ", this.publish);
      }
    },
    getValue() {
      return this.params.value;
    },
    onStatusClick(selectedOption) {
      this.params.onStatusChange({
        oldStatus: this.status.name,
        newStatus: selectedOption.value,
        borrow_asset_id: this.params.data.borrow_asset_id,
      });
      this.params.stopEditing();
    },
    closeDropdown() {
      this.params.stopEditing();
    },
    changeStatus(option) {
      this.$emit("changeStatus", {
        is_publish: option,
      });
      this.params.showPublish({
        is_publish: option,
        catalog_id: this.params.value.catalog_id,
      });
      this.params.stopEditing();
    },
  },
  created() {
    const { name, options } = this.params.value;
    this.status = {
      name,
      options,
    };
  },
  props: {
    is_publish: { catalog_id: Number, is_publish: Number },
  },
};
</script>
  
  <style lang="scss" scoped>
.status-card {
  padding: 0 10px;
  border: 1px solid #e0e2e7;
  border-radius: 8px;
  p {
    margin: 0;
  }
}

.dropdown {
  display: flex;
  flex-direction: column;
  width: 130px;
  cursor: pointer;
}

.menu-dropdown {
  background-repeat: no-repeat;
  background-position: 97% 50%;
  padding: 0 40px 0 10px;
  border: 1px solid #e0e2e7;
  border-radius: 8px;
}

#selectDiv {
  width: 130px;
  height: 32px;
  display: grid;
  grid-template-columns: 5fr 2fr;
  border-radius: 7px;
  text-align: center;
  div {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    img {
      width: 10px;
    }
  }
}
.dropdown-content {
  width: 130px;
  // min-width: 190px;
  border-radius: 7px;
  // padding: 1px 1px;
  border: 1px solid black;
  overflow: hidden;

  .option {
    text-align: center;
    line-height: normal;
    border-radius: 0 !important;
    background: white;
    &:hover {
      color: #007afe;
    }
  }
}

.Publish,
.Unpublish,
.Schedule {
  width: 100%;
  border: 0;

  padding: 0.5rem 1rem;
  border-radius: 7px;
  border-right: 1rem solid transparent;
  cursor: pointer;
}

.Publish-dropdown {
  color: #2cbe3f;
  background-color: #beffc7;
}

.Unpublish-dropdown {
  background-color: #ffbebe;
  color: #be2c2c;
}
.Schedule-dropdown {
  background-color: #e3ddff;
  color: #7b60fc;
}

.Publish:hover {
  color: #2cbe3f;
  background-color: #beffc7;
}

.Unpublish:hover {
  background-color: #ffbebe;
  color: #be2c2c;
}
.Schedule:hover {
  background-color: #e3ddff;
  color: #7b60fc;
}
</style>
  